// string
export const capitalize = (str: string) => {
    return str.slice(0, 1).toUpperCase() + str.slice(1);
};

// number
export const isNumber = (value: number | string) => typeof value === "number" && isFinite(value);

export const isIOSDevice = () => {
    if (typeof window !== "undefined" && navigator) {
        return /iPad|iPhone|iPod/.test(navigator.userAgent);
    }
    return false;
};
