import { getCookieValue } from "@lib/analytics/getCookieValue";
import { LogTag, Logger, ServiceType } from "@lib/monitoring/logger";
import { hasWindow } from "@lib/utils/hasWindow";

export const isAnalyticsCookiesAllowed = (): boolean => {
    if (!hasWindow) {
        return false;
    }

    const cookieValue = getCookieValue("CookieInformationConsent");

    const decodedValue = decodeURIComponent(cookieValue ?? "{}");

    let parsedValue;
    try {
        parsedValue = JSON.parse(decodedValue);
    } catch (error) {
        Logger.error(ServiceType.WEB, "Error parsing cookie value:", {
            tag: LogTag.ANALYTICS,
            error,
        });
        return false;
    }

    if (parsedValue && Array.isArray(parsedValue.consents_approved)) {
        return parsedValue.consents_approved.includes("cookie_cat_statistic");
    }

    return false;
};
