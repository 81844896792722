import type { SearchClient, SearchIndex } from "algoliasearch";
import algoliasearch from "algoliasearch";
import type { BrowseOptions } from "@algolia/client-search";
import type { SearchOptions } from "instantsearch.js";
import type { RequestOptions } from "@algolia/transporter";
import { getAlgoliaIndexName, normalizedBrowseObjects } from "./helpers";
import type { AlgoliaProduct } from "./types";

const APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || "";
const SEARCH_API_KEY = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || "";
const BROWSE_API_KEY = process.env.ALGOLIA_BROWSE_ACL_ONLY_API_KEY || "";

export declare type BrowseParams = SearchOptions & BrowseOptions<AlgoliaProduct> & RequestOptions;

export type AlgoliaClient = SearchClient & {
    index: SearchIndex;
    browseObjects: (params: BrowseParams) => Readonly<Promise<void>>;
};

class AlgoliaClientWrapper {
    readonly client: AlgoliaClient;

    constructor(apiKey: string, indexName: string) {
        this.client = this.createAlgoliaClient(apiKey, indexName);
    }

    private createAlgoliaClient(apiKey: string, indexName: string): AlgoliaClient {
        const client = algoliasearch(APP_ID, apiKey);
        const index = client.initIndex(indexName);

        return {
            ...client,
            index,
            // Override library "browseObjects" function to normalize the response
            browseObjects: (params: BrowseParams) => normalizedBrowseObjects(index, params),
        };
    }
}

const algoliaBrowse = (locale: any) => {
    return new AlgoliaClientWrapper(BROWSE_API_KEY, getAlgoliaIndexName(locale)).client;
};

const algoliaSearch = (locale: any) => {
    return new AlgoliaClientWrapper(SEARCH_API_KEY, getAlgoliaIndexName(locale)).client;
};
export { algoliaBrowse, algoliaSearch };
