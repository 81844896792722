import { gtmScriptString } from "@lib/analytics/gtm";
import Script from "next/script";

export const GtmScript = () => {
    const initialGtmScript = `window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }
gtag('consent', 'default', {
    'ad_storage': 'denied',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
    'analytics_storage': 'denied',
    'wait_for_update': 500
});
gtag('set', 'ads_data_redaction', true);
gtag('set', 'url_passthrough', true);`;

    return (
        <>
            <Script
                id="gtmInitialScript"
                dangerouslySetInnerHTML={{ __html: initialGtmScript }}
            ></Script>
            <Script
                id="gtmScript"
                strategy="lazyOnload"
                dangerouslySetInnerHTML={{ __html: gtmScriptString }}
            />
        </>
    );
};
