import { isRobotNoFollow, isRobotNoIndex } from "@components/seo/utils";
import type { ISeoMetadata } from "@contentful-api/types/contentful";
import { getCanonicalUrl } from "@lib/utils/getCanonicalUrl";
import type { DefaultSeoProps as _DefaultSeoProps } from "next-seo";
import { DefaultSeo as _DefaultSeo } from "next-seo";
import { useRouter } from "next/router";

interface DefaultSeoProps extends _DefaultSeoProps {
    seoModule: ISeoMetadata;
    pathName: string;
}

export const DefaultSeo = ({ seoModule, pathName, ...nextSeoProps }: DefaultSeoProps) => {
    const title = seoModule?.fields?.title;
    const description = seoModule?.fields?.description;
    const robotFollow = seoModule?.fields?.robotFollow;
    const robotIndex = seoModule?.fields?.robotIndex;
    const openGraphImage = seoModule?.fields?.openGraphImage?.fields;
    const openGraphTitle = seoModule?.fields?.openGraphTitle;
    const openGraphMetaDescription = seoModule?.fields?.openGraphMetaDescription;

    const { locale, defaultLocale } = useRouter();

    return (
        <_DefaultSeo
            {...nextSeoProps}
            title={title}
            description={description}
            openGraph={{
                type: "website",
                title: openGraphTitle ?? title,
                ...(openGraphMetaDescription && { description: openGraphMetaDescription }),
                ...(openGraphImage && {
                    images: [
                        {
                            url: openGraphImage?.file?.url,
                            alt: openGraphImage?.description,
                            width: openGraphImage?.file?.details?.image?.width,
                            height: openGraphImage?.file?.details?.image?.height,
                        },
                    ],
                }),
                url: getCanonicalUrl(locale === defaultLocale ? pathName : `/${locale + pathName}`),
            }}
            canonical={getCanonicalUrl(
                locale === defaultLocale ? pathName : `/${locale + pathName}`
            )}
            {...(isRobotNoFollow(robotFollow) && { dangerouslySetAllPagesToNoFollow: true })}
            {...(isRobotNoIndex(robotIndex) && { dangerouslySetAllPagesToNoIndex: true })}
        />
    );
};
