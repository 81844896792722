import type { LineItem, Product, ProductVariant } from "@graphql/generated/components";
import { Attributes } from "@lib/enums/ProductAttributes";
import { getProductAttributeValue } from "@lib/utils/attributesUtils";
import { isNumber, isString } from "lodash-es";

export const getImperialSizeForProduct = (
    item: LineItem | (Product & { variant?: Partial<ProductVariant> })
) => {
    const size = getProductAttributeValue(item?.variant?.attributesRaw, Attributes.sizeUS);
    const hasSizeUS = isString(size) || isNumber(size);
    return hasSizeUS ? String(size) : "";
};
