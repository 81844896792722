import { hasWindow } from "@lib/utils/hasWindow";
export const gtmId = "GTM-KKNLZ59";

export const gtmScriptString = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');
`;

export const GTMPageView = (url: string) => {
    if (!hasWindow) {
        return;
    }

    const event = {
        event: "pageFullyLoaded",
        page: url,
    };

    window.dataLayer && window.dataLayer.push(event);
    return event;
};
