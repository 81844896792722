import type { NextWebVitalsMetric } from "next/app";
import { Logger, LogTag, ServiceType } from "./logger";

export type CustomMetric = {
    id: string;
    startTime: number;
    value: number;
    label: string;
    name: string;
};

export const reportMetrics = async (metric: NextWebVitalsMetric) => {
    Logger.warn(ServiceType.WEB, "NextWebVitalsMetric", {
        tag: LogTag.PERFORMANCE,
        ...metric,
    });
};
