export function getCookieValue(name: string): string | null {
    const nameEQ = name + "=";
    const cookies = document?.cookie?.split(";") ?? [];

    for (const cookie of cookies) {
        const trimmedCookie = cookie.trim();
        if (trimmedCookie.startsWith(nameEQ)) {
            return trimmedCookie.substring(nameEQ.length);
        }
    }

    return undefined;
}
