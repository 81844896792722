import type { Me } from "@store/index";
import { getCookieValue } from "@lib/analytics/getCookieValue";
import { hasWindow } from "@lib/utils/hasWindow";

export const getAlgoliaUserToken = (me: Me) => {
    return me?.customer?.id ?? getAlgoliaTokenFromCookies();
};

const getAlgoliaTokenFromCookies = () => {
    return hasWindow ? getCookieValue("_ALGOLIA") : undefined;
};
