import { PRODUCT_IMAGE_PLACEHOLDER_URL } from "@ui/components/media-and-icons/image/imagePaths";
import type { SearchIndex } from "algoliasearch";
import type { AlgoliaProduct } from "./types";
import type { BrowseParams } from "./algoliaWrapper";
import { getCountryForSiteByLocale, getCurrencyForSiteByLocale } from "@config/site/site-config";

export function normalizeAlgoliaProducts(products: AlgoliaProduct[]) {
    return products.map((hit) => ({
        ...hit,
        image: hit.image || PRODUCT_IMAGE_PLACEHOLDER_URL,
        images: hit.images?.length ? hit.images : [PRODUCT_IMAGE_PLACEHOLDER_URL],
        productColors:
            hit.productColors?.map((color) => ({
                ...color,
                priceWithDiscount: color.priceRaw,
                priceWithoutDiscount: color.listPriceRaw,
                image: color.image || PRODUCT_IMAGE_PLACEHOLDER_URL,
            })) ?? [],
    }));
}

export function normalizedBrowseObjects(indexToBrowse: SearchIndex, params: BrowseParams) {
    return indexToBrowse.browseObjects({
        ...params,
        ...(params.batch && {
            batch: (batch: AlgoliaProduct[]) => {
                const normalizedBatch = normalizeAlgoliaProducts(batch);

                if (params.batch) {
                    params.batch(normalizedBatch);
                }
            },
        }),
    });
}

export function getAlgoliaIndexName(locale: string) {
    const env = process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT === "master" ? "prod" : "test";
    const country = getCountryForSiteByLocale(locale);
    const currency = getCurrencyForSiteByLocale(locale);
    return `${env.toLowerCase()}_${country}_${locale}_${currency}_Products`;
}
