import Script from "next/script";

export interface SmartBannerProps {
    iOSAppId: string;
    googleAppId: string;
}

export const SmartBannerMeta = ({ iOSAppId, googleAppId }: SmartBannerProps) => {
    return (
        <>
            {/* iOS */}
            <meta name="apple-itunes-app" content={`app-id=${iOSAppId}`} />

            {/* Android */}
            <meta name="smartbanner:title" content="ECCO" />
            <meta name="smartbanner:author" content="ECCO Sko AS" />
            <meta name="smartbanner:price" content="FREE" />
            <meta name="smartbanner:price-suffix-google" content=" " />
            <meta
                name="smartbanner:icon-apple"
                content="https://play-lh.googleusercontent.com/hyqIw5mUSXsxYxKnBNQJMyGCKUexrJz5pSnYc1waFjk-H207h_MiWYKecFPM39S6Vzs=w480-h9600"
            />
            <meta
                name="smartbanner:icon-google"
                content="https://play-lh.googleusercontent.com/hyqIw5mUSXsxYxKnBNQJMyGCKUexrJz5pSnYc1waFjk-H207h_MiWYKecFPM39S6Vzs=w480-h9600"
            />
            <meta name="smartbanner:button" content="Install" />
            <meta
                name="smartbanner:button-url-apple"
                content={`https://apps.apple.com/app/id${iOSAppId}`}
            />
            <meta
                name="smartbanner:button-url-google"
                content={`https://play.google.com/store/apps/details?id=${googleAppId}`}
            />
            <meta name="smartbanner:enabled-platforms" content="android" />
            <meta name="smartbanner:line1" content="Premium comfort footwear" />
            <meta name="smartbanner:line2" content="[r:4.7]" />
            <meta name="smartbanner:line3" content="ECCO" />
            <meta name="smartbanner:stars-color" content="#000000" />
        </>
    );
};

export const SmartBannerScript = () => {
    return (
        <Script
            id="smart-banner-script"
            src="https://cdn1.imshop.ai/assets/app/b3.min.js"
            strategy="afterInteractive"
        />
    );
};
