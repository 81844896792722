import { v4 as uuidv4 } from "uuid";
import kountSDK from "@kount/kount-web-client-sdk";
import { getCountryForSite } from "@config/site/site-config";

const clientID = {
    US: "100343",
    CA: "100447",
};

let sessionID = null;
export const getSessionIDForKount = () => {
    if (sessionID) {
        return sessionID;
    }
    sessionID = uuidv4().replace(/-/g, "").substring(0, 32);
    return sessionID;
};

const kountConfig = {
    clientID: clientID[getCountryForSite],
    // We are using includes because the value for Adyen Environment can be either LIVE or LIVE-countryCode (e.g. LIVE-US)
    environment: process.env.NEXT_PUBLIC_ADYEN_ENVIRONMENT.includes("LIVE") ? "PROD" : "TEST",
    isSinglePageApp: false,
};

export const initializeKount = () => {
    if (getCountryForSite === "CA" || getCountryForSite === "US") {
        const KountSessionID = getSessionIDForKount();
        return new kountSDK(kountConfig, KountSessionID);
    }
    return null;
};
